.header {
    background: #001529;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  .navbar-left, .navbar-center, .navbar-right {
    font-size: 16px;
    color: #fff;
  }
  
  .content {
    padding: 20px;
  }
  
  .table {
    margin-top: 20px;
  }
  