/* Login.css */

.login-container {
  max-width: 500px;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
}

.logo img {
  width: 250px; 
  margin-bottom: 20px;
}

.login-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.login-form-button {
  width: 100%;
  height: 45px;
  font-size: 18px;
}

.ant-input, .ant-input-password {
  height: 45px;
  font-size: 16px;
  padding: 0 10px;
  box-sizing: border-box;
}

.gradient-button {
  background: linear-gradient(to right, #3eac50, #8ec958); /* Example gradient (from pink to orange) */
  border: none;  /* Remove border */
  color: white;  /* Text color */
  padding: 10px 20px;  /* Adjust padding */
  font-size: 16px;  /* Adjust font size */
  cursor: pointer;  /* Show pointer on hover */
}
