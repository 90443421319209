.image-container {
    position: relative;
    text-align: center;
    max-width: 80%; /* จำกัดความกว้างของ container */
    margin: auto; /* จัดให้กลาง container */
  }
  
  .img-fluid {
    width: 100%; /* ทำให้รูปเต็มความกว้างของ container */
    max-width: 500px; /* จำกัดขนาดสูงสุดของรูปภาพ (คุณสามารถปรับขนาดนี้ได้) */
    height: auto; /* รักษาสัดส่วนของรูปภาพ */
    border-radius: 8px; /* ทำให้รูปมีมุมโค้ง */
  }
  
  .info-button {
    position: absolute;
    bottom: 20px; /* ระยะห่างจากด้านล่างของภาพ */
    left: 50%;
    transform: translateX(-50%); /* ทำให้ปุ่มอยู่กลาง */
    padding: 10px 20px;
    background-color: #007bff; /* สีพื้นหลังปุ่ม */
    color: white; /* สีตัวอักษร */
    border: none;
    border-radius: 5px; /* มุมโค้งของปุ่ม */
    cursor: pointer; /* เปลี่ยนเป็น cursor pointer เมื่อ hover */
    font-size: 16px; /* ขนาดตัวอักษร */
    transition: background-color 0.3s; /* เอฟเฟกต์ hover */
  }
  
  .info-button:hover {
    background-color: #0056b3; /* สีพื้นหลังของปุ่มเมื่อ hover */
  }
  